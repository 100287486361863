import { useState } from "react";
import { Typography, TextField, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import "../styles/index.css";

interface DataInputProps {
    fieldLabels: string[];
    onSubmit: (response: responseSchema[]) => void;
}

interface responseSchema {
    Info: MyFormData[];
    Detail: MyFormData[];
}

interface MyFormData {
    Name: string;
    Value: string;
}

interface PatientData {
    Name: string;
    Value: string;
}

const PatientLabels: string[] = [
    "Weight (Kg)",
    "Height (cm)",
    "Blood Pressure (mmHg)",
    "Pulse Rate (bpm)",
];

function DataInput({ fieldLabels, onSubmit }: DataInputProps) {
    const [formData, setFormData] = useState<Record<string, string>>({});

    const handleInputChange = (field: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleFormClear = () => {
        setFormData({});
    };

    const handleFormSubmit = () => {
        const PatientItem: PatientData[] = PatientLabels.map((label) => ({
            Name: label,
            Value: formData[label] || "",
        }));
        const formDataArray: MyFormData[] = fieldLabels.map((label) => ({
            Name: label,
            Value: formData[label] || "",
        }));
        const response = [
            {
                Info: PatientItem,
                Detail: formDataArray,
            },
        ];
        onSubmit(response);
    };

    return (
        <div
            className="box"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                <Typography variant="h3" style={{ textAlign: "center" }}>
                    Data
                </Typography>
                <Typography variant="h4">PatientInfo</Typography>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                    {PatientLabels.map((label, index) => (
                        <TextField
                            key={index}
                            label={label}
                            variant="outlined"
                            fullWidth
                            size="small"
                            style={{ marginRight: "16px" }}
                            margin="normal"
                            value={formData[label] || ""}
                            onChange={(e) =>
                                handleInputChange(label, e.target.value)
                            }
                        />
                    ))}
                </div>
            </div>
            <div>
                {fieldLabels.map((label, index) => (
                    <TextField
                        key={index}
                        label={label}
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="normal"
                        value={formData[label] || ""}
                        onChange={(e) =>
                            handleInputChange(label, e.target.value)
                        }
                    />
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "10px",
                }}>
                <Button
                    className="darkButton"
                    variant="contained"
                    style={{ flex: "80%" }}
                    endIcon={<SendIcon />}
                    onClick={handleFormSubmit}>
                    Submit
                </Button>
                <Button
                    className="darkButton"
                    style={{ flex: "10%" }}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={handleFormClear}>
                    DEL
                </Button>
            </div>
        </div>
    );
}

export default DataInput;
