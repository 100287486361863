import { useState } from "react";
import { Grid } from "@mui/material";
import "./styles/index.css";
import Header from "./components/Header";
import DataInput from "./components/DataInput";
import Prediction from "./components/Prediction";

interface MyFormData {
    Info: PatientData[];
    Detail: PatientData[];
}

interface PatientData {
    Name: string;
    Value: string;
}

interface icdResponse {
    label: string;
    probability: number;
    description: string;
}

// eslint-disable-next-line
const mockPredictionItems: string[] = [
    "Prediction 1",
    "Prediction 2",
    "Prediction 3",
    "Prediction 4",
    "Prediction 5",
    "Prediction 6",
    "Prediction 7",
    "Prediction 8",
    "Prediction 9",
    "Prediction 10",
    // ... add more mock data items
];
const fieldLabels: string[] = [
    "Chief Complaint",
    "Present Illness",
    "Physical Examination",
    "Previous Treatment",
    "Underlying Disease",
    "Provisional Diagnosis",
    "Reason Of Admission",
    "Treatment",
];

function App() {
    const [predictionItems, setPredictionItems] = useState<icdResponse[]>([]);
    const [jsonklangData, setJsonklangData] = useState({});

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<boolean[]>(
        Array(fieldLabels.length).fill(false)
    );

    const handleCheckboxChange = (index: number) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
    };

    const handleAcknowledgeSubmit = () => {
        setPredictionItems([]);
    };

    const handleSubmit = async (formDataArray: MyFormData[]) => {
        try {
            const filteredInfo = formDataArray[0].Info.filter(
                (data) => data.Value !== ""
            );
            const filteredDetail = formDataArray[0].Detail.filter(
                (data) => data.Value !== ""
            );

            const requestBody = {
                Patient: {
                    LineItem: [
                        {
                            GroupName: "visit_information_list",
                            Code: "",
                            Item: filteredInfo.map((data) => ({
                                Code: "",
                                Name: "",
                                Nameen: data.Name,
                                Value: [data.Value],
                                Unit: "",
                            })),
                        },
                    ],
                    LineItemUnstructure: {
                        GroupName: "order_information_list",
                        Code: "",
                        Item: filteredDetail.map((data) => ({
                            Amount: "",
                            Code: data.Name,
                            Name: data.Name,
                            Number: "",
                            Usage: "",
                            Value: [data.Value],
                        })),
                    },
                },
            };

            setJsonklangData(requestBody);
            const response = await fetch(
                // "http://127.0.0.1:5010/api/icd/predict",
                "http://compute-model:5010/api/icd/predict",
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (response.ok) {
                const predictionData: icdResponse[] = await response.json();
                setPredictionItems(predictionData);
            } else {
                console.error("API error:", response.statusText);
            }
        } catch (error) {
            console.error("Network error:", error);
        }
    };

    return (
        <Grid container spacing={3} style={{ height: "100vh" }}>
            <Grid item xs={12} style={{ height: "100px" }}>
                <Header title="ICD 10 Prediction" />
            </Grid>
            <Grid
                container
                item
                xs={12}
                spacing={3}
                style={{ margin: "-12px 24px 24px 0" }}>
                <Grid
                    item
                    xs={6}
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <DataInput
                        fieldLabels={fieldLabels}
                        onSubmit={handleSubmit}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Prediction
                        items={predictionItems}
                        jsonklang={jsonklangData}
                        onCheckboxChange={handleCheckboxChange}
                        onAcknowledgeChange={handleAcknowledgeSubmit} // Rename this to handleAcknowledgeSubmit
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: "25px" }}></Grid>
        </Grid>
    );
}

export default App;
